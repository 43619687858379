import Http from '@/api/http/';

export default class AppHomeContent extends Http {
  constructor() {
    super();
  }

  /**
   * 新增app首页内容
   * @param {Body}
   * @returns
   */
  appHomeContent_addAppHomeContent() {
    return super.post('appHomeContent/addAppHomeContent', ...arguments);
  }

  /**
   * 获取首页内容详情
   * @param { id }
   * @returns
   */
  appHomeContent_appHomeContentDetail() {
    return super.post('appHomeContent/appHomeContentDetail', ...arguments);
  }

  /**
   * 获取内容列表
   * @param { Body }
   * @returns
   */
  appHomeContent_appHomeContentDetailList() {
    return super.post('appHomeContent/appHomeContentDetailList', ...arguments);
  }

  /**
   * 获取内容列表
   * @param { Body }
   * @returns
   */
  appHomeContent_appHomeContentListByPage() {
    return super.post('appHomeContent/appHomeContentListByPage', ...arguments);
  }

  /**
   * 删除内容
   * @param { id }
   * @returns
   */
  appHomeContent_deleteAppHomeContent() {
    return super.post('appHomeContent/deleteAppHomeContent', ...arguments);
  }

  /**
   * 上下线首页内容
   * @param {recordStatus, id}
   * @returns
   */
  appHomeContent_onOffLineAppHomeContent() {
    return super.post('appHomeContent/onOffLineAppHomeContent', ...arguments);
  }

  /**
   * 修改app首页内容
   * @param {Body}
   * @returns
   */
  appHomeContent_updateAppHomeContent() {
    return super.post('appHomeContent/updateAppHomeContent', ...arguments);
  }

  /**
   * 生成链接
   * @param { id }
   */
  appHomeContent_convertLink() {
    return super.post('appHomeContent/convertLink', ...arguments);
  }
}
