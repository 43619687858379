import Http from '../api/http';

export default class TabInfo extends Http {
  constructor() {
    super();
  }
  // tab所有选项查询
  tabInfo_getList() {
    return super.post('tabInfo/getList', ...arguments);
  }
  tabInfo_add() {
    return super.post('tabInfo/add', ...arguments);
  }
  tabInfo_update() {
    return super.post('tabInfo/update', ...arguments);
  }
  tabInfo_changeStatus() {
    return super.post('tabInfo/changeStatus', ...arguments);
  }
  // 查询设置tab配置值
  tabConfig_getInfos() {
    return super.post('tabConfig/getInfos', ...arguments);
  }
  // 不同用户设置Tab
  tabConfig_addOrUpdate() {
    return super.post('tabConfig/addOrUpdate', ...arguments);
  }
  /**
   * 探长分享 tab下拉
   * @returns
   */
  tabInfo_getListForDropDown() {
    return super.post('tabInfo/getListForDropDown', ...arguments);
  }
}
