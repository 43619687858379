import Http from '@/api/http/';

export default class Popup extends Http {
  constructor() {
    super();
  }
  /**
   *  弹窗列表
   *  @param {integer}  type -类型 0 app 1小程序
   *
   */
  popup_getList() {
    return super.post('popup/getList', ...arguments);
  }
  /**
   * 弹窗上下线
   * @param {string} id -弹窗id
   * @param {string} status -状态 0上线 1下线
   */
  popup_changeStatus() {
    return super.post('popup/changeStatus', ...arguments);
  }
  /**
   * 修改弹窗配置
   * @param {string} id -弹窗id
   * @param {Array} files -图片
   * @param {integer} popupType -弹窗类型 0：居中，1：底部，2：底部消息
   *
   */
  popup_updateInfo() {
    return super.post('popup/updateInfo', ...arguments);
  }
  /**
   * 弹窗详情
   * @param {string} id -弹窗id
   */
  popup_detail() {
    return super.post('popup/detail', ...arguments);
  }
}
