import Http from '../api/http';

export default class TagInfo extends Http {
  constructor() {
    super();
  }
  tagInfo_getList() {
    return super.post('tagInfo/getList', ...arguments);
  }
  tagInfo_changeStatus() {
    return super.post('tagInfo/changeStatus', ...arguments);
  }
  tagInfo_add() {
    return super.post('tagInfo/add', ...arguments);
  }
  tagInfo_update() {
    return super.post('tagInfo/update', ...arguments);
  }
  /**
   * 内容标签下拉
   * @returns
   */
  tagInfo_getListForDropDown() {
    return super.post('tagInfo/getListForDropDown', ...arguments);
  }
}
