import Http from '../api/http/';
export default class Common extends Http {
  constructor() {
    super();
  }
  /***********通用服务接口***************/
  /**
   * 首页看板
   */
  common_dashboard() {
    return super.post('common/dashboard', ...arguments);
  }
  /**
   * 数字字典
   */
  common_dictionary() {
    return super.post('common/dictionary', ...arguments);
  }
  /**
   * 单个数字字典
   */
  common_dictionarySingleType() {
    return super.post('common/dictionarySingleType', ...arguments);
  }
  /**
   * 国家区号列表
   */
  common_getCountryCodeList() {
    return super.post('common/getCountryCodeList', ...arguments);
  }

  /**
   * 根据偏移量获取工作日
   * @param {integer} offset -  offset
   */
  common_getWorkDateByOffset() {
    return super.post('common/getWorkDateByOffset', ...arguments);
  }

  /**
   * 获取认证类型
   * @returns
   */
  common_getCertificateList() {
    return super.post('common/getCertificateList', ...arguments);
  }

  /**
   * 获取系统区域
   * @param {integer} sysAreaType -  系统区域类型 1城市 2行政区
   * @param {string} cityName -  城市名称
   * @param {string} countyName -  行政区名称
   */

  common_getSysArea() {
    return super.post('common/getSysArea', ...arguments);
  }

  /**
   * 获取系统市区
   */

  common_getCountryCityList() {
    return super.post('common/getCountryCityList', ...arguments);
  }
  /**
   * 数据字典
   * @param {integer} typeList -  类型
   */
  common_getListByType() {
    return super.post('common/getListByType', ...arguments);
  }
}
