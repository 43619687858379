import Http from '../api/http/';

export default class Store extends Http {
  constructor() {
    super();
  }
  /***********P端门店管理***************/
  /**
   * 添加门店
   * @param {Array} req -  req
   */
  store_addStore() {
    return super.post('store/addStore', ...arguments);
  }

  /**
   * 删除门店
   * @param {string} storeId -  storeId
   */
  store_deleteStore() {
    return super.post('store/deleteStore', ...arguments);
  }

  /**
   * 获取区域门店门店列表
   * @param {string} areaId -  企业区域id
   * @param {string} companyId -  企业id
   */
  store_getAreaStoreList() {
    return super.post('store/getAreaStoreList', ...arguments);
  }

  /**
   * 通过id查询门店
   * @param {string} storeId -  storeId
   */
  store_getInfoById() {
    return super.post('store/getInfoById', ...arguments);
  }

  /**
   * 获取门店城市分组列表
   * @param {string} companyId -  企业id
   */
  store_getGroupStoreList() {
    return super.post('store/getGroupStoreList', ...arguments);
  }

  /**
   * 获取门店列表
   * @param {string} brandId -  品牌id
   * @param {string} brandName -  门店名称
   * @param {integer} page -  页数
   * @param {integer} rows -  每页行数
   */
  store_getStoreList() {
    return super.post('store/getStoreList', ...arguments);
  }

  /**
   * 门店上下线
   * @param {string} storeId -  storeId
   * @param {integer} status -  只能传0：上线 2：下线
   */
  store_storeOnOffLine() {
    return super.post('store/storeOnOffLine', ...arguments);
  }

  /**
   * 修改门店
   * @param {Array} req -  req
   */
  store_updateStore() {
    return super.post('store/updateStore', ...arguments);
  }

  /**
   * 导出门店
   * @param brandId 品牌ID
   */
  store_exportByBrandId() {
    return super.get('store/exportByBrandId', ...arguments);
  }

  /**
   * 根据品牌获取门店
   * @param  brandId
   */
  store_getListByBrandId() {
    return super.post('store/getListByBrandId', ...arguments);
  }
  /**
   * 根据品牌获取门店 带权限
   * @param  brandId
   */
  store_getListByPermission() {
    return super.post('store/getListByPermission', ...arguments);
  }

  /***
   * 批量修改门店
   * ids 门店id,以','分割
   * requireContent 门店其它要求
   * isWash 是否有洗手台 0:有,1:没有
   * isSnack 是否有小吃台 0:有,1:没有
   * remake 备注
   */
  store_batchUpdateRequire() {
    return super.post('store/batchUpdateRequire', ...arguments);
  }
  /**
   * 批量导入门店
   * @param brandId
   * @param excelFile
   */
  store_import() {
    return super.post('store/import', ...arguments);
  }

  /**
   * 导出品牌下的门店批量修改模板
   * @param brandId 品牌id
   */
  store_exportBatchUpdateTemplate() {
    return super.get('store/exportBatchUpdateTemplate', ...arguments);
  }

  /**
   * 批量修改门店字段
   * @param excelFile 文件
   */
  store_importBatchUpdateStore() {
    return super.post('store/importBatchUpdateStore', ...arguments);
  }

  /**
   * 导出企业下门店
   * @param companyId 企业id
   */
  store_exportByCompanyId() {
    return super.get('store/exportByCompanyId', ...arguments);
  }

  //按月度查询门店明细
  store_getInfoByMonth() {
    return super.post('store/getInfoByMonth', ...arguments, {});
  }

  //按月度导出门店明细
  store_exportDetailByMonth() {
    return super.get('store/exportDetailByMonth', ...arguments);
  }

  /**
   * 地址检索
   * @param
   * place: 地址关键字
   * region： 搜索区域
   * pid： 国家
   */
  store_searchPlace() {
    return super.post('store/searchPlace', ...arguments);
  }
  /**
   * 企业id
   * @param
   * companyId
   */
  store_getListByCompanyId() {
    return super.post('store/getListByCompanyId', ...arguments);
  }
  // 品牌 → 城市 → 门店分组
  store_getStoreGroupByBrandAndCity() {
    return super.post('store/getStoreGroupByBrandAndCity', ...arguments);
  }
  // 通过门店或者品牌判断禁用启用状态
  store_judgeStatus() {
    return super.post('store/judgeStatus', ...arguments);
  }
  store_modifyStore() {
    return super.post('store/modifyStore', ...arguments);
  }
  /**
   * 获取任务门店平铺（下拉）列表
   * @param {string} brandId -  品牌id
   * @param {string} storeName -  门店名称
   * @param {string} storeId -  门店id
   * @param {integer} cityId -  城市id
   * @param {string} areaId -  区域id
   */
  store_getTaskStoreList() {
    return super.post('store/getTaskStoreList', ...arguments);
  }

  store_batchDeleteStore() {
    return super.post('store/batchDeleteStore', ...arguments);
  }
  /**
   * 批量门店上下线
   * @param {string} storeIds -  英文分号隔开
   * @param {integer} status -  只能传0：上线 2：下线
   */

  store_batchStoreOnOffLine() {
    return super.post('store/batchStoreOnOffLine', ...arguments);
  }
  /**
   * 获取门店按照区域树
   * @param {Array} storeTreeDto -  storeTreeDto
   */

  store_getAreaStoreTree() {
    return super.post('store/getAreaStoreTree', ...arguments, {});
  }
  /**
   * 获取门店列表
   * @param {Array} storeListDto -  storeListDto
   */
  store_getStoreListV2() {
    return super.post('store/getStoreListV2', ...arguments, {});
  }

  /**
   * 批量修改门店字段
   * @param {Array} req -  req
   */

  store_batchUpdate() {
    return super.post('store/batchUpdate', ...arguments, {});
  }
  /**
   * 导出门店列表
   * @param {Array} storeListDto -  storeListDto
   */
  store_exportStoreList() {
    return super.post('store/exportStoreList', ...arguments, {});
  }
  /**
   * 获取门店标签下拉列表
   * @param {string} brandId -  品牌id
   * @param {string} companyId -  企业id
   * @param {string} tagId -  标签id
   */
  store_getStoreTagList() {
    return super.post('store/getStoreTagList', ...arguments);
  }
}
