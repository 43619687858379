let baseURL = 'djzchina-ops-p-api/',
  origin = 'https://dev.p.haitan.shop/',
  version = '5.6.2';

//生产环境配置
if (process.env.NODE_ENV === 'production') {
  baseURL = location.origin + '/djzchina-ops-p-api/';
  origin = location.origin + '/';
}

module.exports = {
  baseURL,
  origin,
  version,
};
