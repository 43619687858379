export default [
  /*************APP管理**************************************/
  {
    path: '/market/appManage/schoolSignUp',
    name: 'market_schoolSignUp',
    meta: { title: '校园报名' },
    component: resolve => require(['@/views/market/appManage/schoolSignUp'], resolve),
  },
  {
    path: '/market/appManage/feedback',
    name: 'market_feedback',
    meta: { title: '意见反馈' },
    component: resolve => require(['@/views/market/appManage/feedback'], resolve),
  },
  {
    path: '/market/appManage/bannerManage',
    name: 'market_bannerManage',
    meta: { title: 'Banner管理' },
    component: resolve => require(['@/views/market/appManage/bannerManage'], resolve),
  },
  {
    path: '/market/appManage/openScreenPage',
    name: 'market_openScreenPage',
    meta: { title: '开屏页' },
    component: resolve => require(['@/views/market/appManage/openScreenPage'], resolve),
  },
  {
    path: '/market/appManage/frontPageManage',
    name: 'market_frontPageManage',
    meta: { title: '首页管理' },
    component: resolve => require(['@/views/market/appManage/frontPageManage'], resolve),
  },
  {
    path: '/market/appManage/themeManage',
    name: 'market_themeManage',
    meta: { title: '主题管理' },
    component: resolve => require(['@/views/market/appManage/themeManage'], resolve),
  },
  {
    path: '/market/appManage/medalManage',
    name: 'market_medalManage',
    meta: { title: '勋章管理' },
    component: resolve => require(['@/views/market/appManage/medalManage'], resolve),
  },
  {
    path: '/market/appManage/noviceHelp',
    name: 'market_noviceHelp',
    meta: { title: '新手帮助' },
    component: resolve => require(['@/views/market/appManage/noviceHelp'], resolve),
  },
  {
    path: '/market/appManage/agreementManage',
    name: 'market_agreementManage',
    meta: { title: '协议管理' },
    component: resolve => require(['@/views/market/appManage/agreementManage'], resolve),
  },
  {
    path: '/market/appManage/ruleManage',
    name: 'market_ruleManage',
    meta: { title: '规则管理' },
    component: resolve => require(['@/views/market/appManage/ruleManage'], resolve),
  },
  /*************培训管理**************************************/
  {
    path: '/market/trainManage/contentManage',
    name: 'market_contentManage',
    meta: { title: '内容管理' },
    component: resolve => require(['@/views/market/trainManage/contentManage'], resolve),
  },
  {
    path: '/market/trainManage/contentLabel',
    name: 'market_contentLabel',
    meta: { title: '内容标签' },
    component: resolve => require(['@/views/market/trainManage/contentLabel'], resolve),
  },
  {
    path: '/market/trainManage/tabManage',
    name: 'market_tabManage',
    meta: { title: 'Tab管理' },
    component: resolve => require(['@/views/market/trainManage/tabManage'], resolve),
  },
  {
    path: '/market/trainManage/examinationManage',
    name: 'market_examinationManage',
    meta: { title: '考题管理' },
    component: resolve => require(['@/views/market/trainManage/examinationManage'], resolve),
  },
  {
    path: '/market/trainManage/everyDayExamine',
    name: 'market_everyDayExamine',
    meta: { title: '每日一题' },
    component: resolve => require(['@/views/market/trainManage/everyDayExamine'], resolve),
  },
  {
    path: '/market/trainManage/courseManage',
    name: 'market_courseManage',
    meta: { title: '课程管理' },
    component: resolve => require(['@/views/market/trainManage/courseManage'], resolve),
  },
  {
    path: '/market/trainManage/certificateManage',
    name: 'market_certificateManage',
    meta: { title: '证书管理' },
    component: resolve => require(['@/views/market/trainManage/certificateManage'], resolve),
  },
  /*************官网管理**************************************/
  {
    path: '/market/officialManage/officialNotice',
    name: 'market_officialNotice',
    meta: { title: '官网公告' },
    component: resolve => require(['@/views/market/officialManage/officialNotice'], resolve),
  },
  {
    path: '/market/officialManage/officialBrand',
    name: 'market_officialBrand',
    meta: { title: '官网品牌' },
    component: resolve => require(['@/views/market/officialManage/officialBrand'], resolve),
  },
  {
    path: '/market/officialManage/leavingMessage',
    name: 'market_leavingMessage',
    meta: { title: '官网留言板' },
    component: resolve => require(['@/views/market/officialManage/leavingMessage'], resolve),
  },
  /*************B端管理**************************************/
  {
    path: '/market/bPlatformManage/bPlatformHelp',
    name: 'market_bPlatformHelp',
    meta: { title: 'B端帮助' },
    component: resolve => require(['@/views/market/bPlatformManage/bPlatformHelp'], resolve),
  },
  {
    path: '/market/bPlatformManage/bUpdateRecord',
    name: 'market_bUpdateRecord',
    meta: { title: 'B端更新记录' },
    component: resolve => require(['@/views/market/bPlatformManage/bUpdateRecord'], resolve),
  },
  {
    path: '/market/bPlatformManage/requireFeedback',
    name: 'market_requireFeedback',
    meta: { title: 'B端需求' },
    component: resolve => require(['@/views/market/bPlatformManage/requireFeedback'], resolve),
  },
  /*************邀请管理**************************************/
  {
    path: '/market/inviteManage',
    name: 'market_inviteManage',
    meta: { title: '邀请管理' },
    component: resolve => require(['@/views/market/inviteManage/inviteManage'], resolve),
  },
  /*************p端管理**************************************/
  {
    path: '/market/pPlatformManage/pPlatformHelp',
    name: 'market_pPlatformHelp',
    meta: { title: 'P端帮助' },
    component: resolve => require(['@/views/market/pPlatformManage/pPlatformHelp'], resolve),
  },
  {
    path: '/market/pPlatformManage/updateRecord',
    name: 'market_updateRecord',
    meta: { title: '更新记录' },
    component: resolve => require(['@/views/market/pPlatformManage/updateRecord'], resolve),
  },
  /*************小程序管理**************************************/
  {
    path: '/market/appletManage/homeManage',
    name: 'market_homeManage',
    meta: { title: 'P端帮助' },
    component: resolve => require(['@/views/market/appletManage/homeManage'], resolve),
  },
  /*************弹窗管理**************************************/
  {
    path: '/market/popupManage/popupManage',
    name: 'market_popupManage',
    meta: { title: '弹窗管理' },
    component: resolve => require(['@/views/market/popupManage/popupManage'], resolve),
  },
];
