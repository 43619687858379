import Http from '../api/http/';

export default class TrainCourse extends Http {
  constructor() {
    super();
  }

  /**
   * 常规题培训课程新增
   * @returns
   */
  trainCourse_add() {
    return super.post('trainCourse/add', ...arguments);
  }

  /**
   * 场景题培训课程新增
   * @returns
   */
  trainCourse_addSense() {
    return super.post('trainCourse/addSense', ...arguments);
  }

  /**
   * 培训课程删除
   * @returns
   */
  trainCourse_delete() {
    return super.post('trainCourse/delete', ...arguments);
  }

  /**
   * 删除评论
   * @returns
   */
  trainCourse_deleteTrainConfigComment() {
    return super.post('trainCourse/deleteTrainConfigComment', ...arguments);
  }
  /**
   * 培训课程详情
   * @returns
   */
  trainCourse_detail() {
    return super.post('trainCourse/detail', ...arguments);
  }

  /**
   * 评论列表
   * @returns
   */
  trainCourse_getCommentListById() {
    return super.post('trainCourse/getCommentListById', ...arguments);
  }
  /**
   * 培训课程列表
   * @returns
   */
  trainCourse_list() {
    return super.post('trainCourse/list', ...arguments);
  }

  /**
   * 培训课程下线
   * @returns
   */
  trainCourse_offLine() {
    return super.post('trainCourse/offLine', ...arguments);
  }

  /**
   * 培训课程上线
   * @returns
   */
  trainCourse_onLine() {
    return super.post('trainCourse/onLine', ...arguments);
  }

  /**
   * 回复评论
   * @returns
   */
  trainCourse_trainCourseCommentReply() {
    return super.post('trainCourse/trainCourseCommentReply', ...arguments);
  }
  /**
   * 常规培训课程更新
   * @returns
   */
  trainCourse_update() {
    return super.post('trainCourse/update', ...arguments);
  }

  /**
   * 场景培训课程更新
   * @returns
   */
  trainCourse_updateSense() {
    return super.post('trainCourse/updateSense', ...arguments);
  }

  /**
   * 获证人数
   * @returns
   */
  trainCourse_userCertificateList() {
    return super.post('trainCourse/userCertificateList', ...arguments);
  }

  /**
   * 培训内容
   * @returns
   */
  trainCourse_trainConfigListByPage() {
    return super.post('trainCourse/trainConfigListByPage', ...arguments);
  }

  /**
   * 培训考题
   * @returns
   */
  trainCourse_queryTrainQuestionList() {
    return super.post('trainCourse/queryTrainQuestionList', ...arguments);
  }

  /**
   * 考题导入
   * @returns \
   */
  trainCourse_examImport() {
    return super.post('trainCourse/examImport', ...arguments);
  }

  /**
   * 获证人数导出
   * @returns
   */
  trainCourse_userCertificateOutport() {
    return super.get('trainCourse/userCertificateOutport', ...arguments, 'download');
  }

  /**
   * 获取课程考题
   * @param { body }
   * @returns
   */
  trainCourse_getTrainExamDetail() {
    return super.post('trainCourse/getTrainExamDetail', ...arguments);
  }
  // 获取课程分类下拉列表
  trainCourse_getCategoryIds() {
    return super.post('trainCourse/getCategoryIds', ...arguments);
  }
  // 培训课程排序
  trainCourse_updateViewSort() {
    return super.post('trainCourse/updateViewSort', ...arguments);
  }
}
