import Http from '@/api/http/';

export default class PayCSettlement extends Http {
  /**
   * 获取生成C端结算单列表
   */
  payCSettlement_getCreatePayCSettlementList() {
    return super.post('payCSettlement/getCreatePayCSettlementList', ...arguments);
  }
  /**
   * 发票解析
   * @param {Array} fileUrl -  图片地址
   *
   */
  payCSettlement_getInvoiceRecognitionVoList() {
    return super.post('payCSettlement/getInvoiceRecognitionVoList', ...arguments);
  }
  /**
   * 已结算单列表
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   * @param {string} payCSettlementId -  结算id
   * @param {integer} settlementStatus -  推送状态
   * @param {integer} pscPayVendor - 支付渠道
   * @param {string} createStartTime -  创建开始时间
   * @param {string} createEndTime -  创建结束时间
   */
  payCSettlement_getPayCSettlementList() {
    return super.post('payCSettlement/getPayCSettlementList', ...arguments);
  }
  /**
   * 未结算单列表
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   * @param {string} userName -  用户名
   * @param {string} userPhone -  手机号
   * @param {integer} pscPayVendor - 支付渠道
   * @param {string} payStartTime - 支付开始时间
   * @param {string} payEndTime - 支付结束时间
   */
  payCSettlement_getUnPayCSettlementList() {
    return super.post('payCSettlement/getUnPayCSettlementList', ...arguments);
  }
  /**
   * 生成C端结算校验
   * @param {Array} payInfoDetailRelateRewardIdList  -  结算单ID
   */
  payCSettlement_createPayCSettlementCheck() {
    return super.post('payCSettlement/createPayCSettlementCheck', ...arguments);
  }
  /**
   * 获取结算单详情
   * @param {integer} payCSettlementId -  结算单ID
   */
  payCSettlement_getPayCSettlementDetail() {
    return super.post('payCSettlement/getPayCSettlementDetail', ...arguments);
  }
  /**
   * 生成C端结算单
   * @param {Array} payInfoDetailRelateRewardIdList  -  结算单ID
   */
  payCSettlement_createPayCSettlement() {
    return super.post('payCSettlement/createPayCSettlement', ...arguments);
  }
  /**
   * 撤销结算单
   * @param {Array} payInfoDetailRelateRewardIdList  -  结算单ID
   */
  payCSettlement_recallPayCSettlement() {
    return super.post('payCSettlement/recallPayCSettlement', ...arguments);
  }
  /**
   * 修改C端结算
   * @param {Array} payInfoDetailRelateRewardIdList  -  结算单ID
   */
  payCSettlement_updatePayCSettlement() {
    return super.post('payCSettlement/updatePayCSettlement', ...arguments);
  }
  /**
   * 推送结算单到CRM
   * @param {integer} payCSettlementId -  结算单ID
   */
  payCSettlement_pushPayCSettlementToCrm() {
    return super.post('payCSettlement/pushPayCSettlementToCrm', ...arguments);
  }
  /**
   * 导出已结算单
   * @param {string} payCSettlementId -  结算id
   * @param {integer} settlementStatus -  推送状态
   * @param {integer} pscPayVendor - 支付渠道
   * @param {string} createStartTime -  创建开始时间
   * @param {string} createEndTime -  创建结束时间
   */
  payCSettlement_exportPayCSettlementList() {
    return super.post('payCSettlement/exportPayCSettlementList', ...arguments);
  }
  /**
   * 导出结算单结算明细
   * @param {string} payCSettlementId -  结算单ID
   */
  payCSettlement_exportPayCSettlementRelateReward() {
    return super.post('payCSettlement/exportPayCSettlementRelateReward', ...arguments);
  }
}
