<template>
  <div>
    <el-table :ref="name" :data="tb.data" v-loading="tb.loading" size="medium" class="gl-table" style="width: 100%" v-bind="$attrs" v-on="$listeners" tooltip-effect="light" border stripe>
      <slot name="index">
        <el-table-column label="序号" type="index" align="center" :index="index" width="60"></el-table-column>
      </slot>
      <slot></slot>
    </el-table>
    <div v-if="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        :disabled="tb.loading"
        @current-change="handleCurrentChange"
        :current-page="tb.page"
        :page-sizes="pageSizes"
        :page-size="tb.size"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tb.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: 'glTable',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    load: { type: Function },
    readyLoad: { type: Function },
    firstLoad: {
      type: Boolean,
      default: true,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 20, 30, 50, 100, 200],
    },
  },
  data() {
    return {
      tb: {
        condition: {},
        data: [],
        loading: false,
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },
  created() {
    if (this.readyLoad) this.readyLoad({ ...this.tb });
    if (this.firstLoad) this._loadData();
  },
  methods: {
    /*********外部可调用方法*************/
    //刷新当前数据
    refresh() {
      this._loadData();
    },
    //搜索
    search(condition) {
      this.tb.condition = condition;
      this.reload();
    },
    //重新加载数据
    reload() {
      this.tb.page = 1;
      this._loadData();
    },
    // 序号
    index(num) {
      return (this.tb.page - 1) * this.tb.size + num + 1;
    },
    // 状态
    getLoading() {
      return this.tb.loading;
    },
    // 设置状态
    setLoading(loading) {
      this.tb.loading = loading;
    },
    // 清除选中
    clearSelection() {
      this.$refs[this.name].clearSelection();
    },
    tableInstance() {
      return this.$refs[this.name];
    },
    handleSizeChange(val) {
      this.tb.size = val;
      this.tb.page = 1;
      this._loadData();
    },
    /*********内部私有方法*************/
    handleCurrentChange(val) {
      this.tb.page = val;
      this._loadData();
    },
    _loadData() {
      if (this.tb.loading) return;
      this.tb.loading = true;
      try {
        this.load(this.tb)
          .then(res => {
            if (res) {
              //如果页码大于1，而data没有数据，则向前返回一页
              if (this.tb.page > 1 && res.data.length === 0) {
                this.tb.loading = false;
                this.tb.page = this.tb.page - 1;
                this._loadData();
                return;
              }
              this.tb.data = res.data;
              this.tb.total = res.total;
            }
          })
          .finally(() => {
            this.tb.loading = false;
          });
      } catch (error) {
        this.api.toast(error, 'error');
        this.tb.loading = false;
      }
    },
  },
  mounted() {
    this.$emit('loadCompleted');
  },
};
</script>
