import Http from '../api/http/';
export default class Report extends Http {
  constructor() {
    super();
  }
  /**
   * @description: 下载数据分析报告
   * @param {type}
   * @return:
   */
  report_deliveryReportList() {
    return super.get('report/deliveryReportList', ...arguments);
  }
  //补签
  report_taskSign2P() {
    return super.post('report/taskSign2P', ...arguments);
  }
  //终止任务
  report_endUserTask() {
    return super.post('report/endUserTask', ...arguments);
  }
  //修改预设时间
  report_modifyCameDateToP() {
    return super.post('report/modifyCameDateToP', ...arguments);
  }

  /**
   * 报告审核列表
   * @param {Array} reportAuditListDto -  reportAuditListDto
   */
  report_reportAuditList() {
    return super.post('report/reportAuditList', ...arguments, {});
  }

  //获取门店下拉列表
  report_getStoreDown() {
    return super.post('report/getStoreDown', ...arguments);
  }

  //批量通过报告
  report_batchPassReport() {
    return super.post('report/batchPassReport', ...arguments);
  }

  //报告交付列表
  report_reportDeliveryList() {
    return super.post('report/reportDeliveryList', ...arguments);
  }

  //批量交付报告
  report_reportBatchDelivery2B() {
    return super.post('report/reportBatchDelivery2B', ...arguments);
  }

  //报告交付下载
  report_deliveryReport() {
    return super.get('report/deliveryReport', ...arguments);
  }

  //报告交付
  report_reportDelivery2B() {
    return super.post('report/reportDelivery2B', ...arguments);
  }

  //作废报告
  report_markDelete() {
    return super.post('report/markDelete', ...arguments);
  }
  //重新分析
  report_reAnalysis() {
    return super.post('report/reAnalysis', ...arguments);
  }
  //单据生成列表
  report_getReportReceiptsList() {
    return super.post('report/getReportReceiptsList', ...arguments);
  }
  //强制结束用户的任务
  report_forceEndUserTask() {
    return super.post('report/forceEndUserTask', ...arguments);
  }

  //单据支付列表
  report_getPayment() {
    return super.post('report/getPayment', ...arguments);
  }

  //提交支付
  report_submitPay() {
    return super.post('report/submitPay', ...arguments);
  }

  //支付单据列表
  report_getReceipts() {
    return super.post('report/getReceipts', ...arguments);
  }

  //生成支付单据
  report_addReceipts() {
    return super.post('report/addReceipts', ...arguments);
  }
  //付款明细
  report_receiptsInfo() {
    return super.post('report/receiptsInfo', ...arguments);
  }

  /**
   * 补签申请详情
   * @param {string} applyId -  申请id
   */
  report_taskSignApplyDetail() {
    return super.post('report/taskSignApplyDetail', ...arguments);
  }

  /**
   * 补签申请列表
   * @param {string} userName -  用户姓名
   * @param {integer} status -  状态
   * @param {string} taskName -  任务名称
   * @param {string} brandId -  品牌ID
   * @param {string} reason -  原因
   * @param {integer} rows -  rows
   * @param {integer} page -  page
   */
  report_taskSignApplyList() {
    return super.post('report/taskSignApplyList', ...arguments);
  }
  /**
   * 用户的补签申请处理
   * @param {Array} req -  req
   */
  report_handleTaskSignApply() {
    return super.post('report/handleTaskSignApply', ...arguments);
  }
  //获取报告信息
  report_getReportInfo() {
    return super.post('report/getReportInfo', ...arguments);
  }
  //获取报告比较列表
  report_getReportContrastDetail() {
    return super.post('report/getReportContrastDetail', ...arguments);
  }
  //抄袭标记
  report_updateReportCopy() {
    return super.post('report/updateReportCopy', ...arguments);
  }

  //撤销抄袭标记
  report_removeReportCopy() {
    return super.post('report/removeReportCopy', ...arguments);
  }

  //审核、驳回、不通过报告
  report_auditReportV161() {
    return super.post('report/auditReportV161', ...arguments);
  }
  //报告交付 保存
  report_saveReport() {
    return super.post('report/saveReport', ...arguments);
  }
  //线下支付
  report_offlinePay() {
    return super.post('report/offlinePay', ...arguments);
  }
  //已支付报告列表
  report_reportPaidList() {
    return super.post('report/reportPaidList', ...arguments);
  }
  //已支付报告导出
  report_exportReportPaidList() {
    return super.get('report/exportReportPaidList', ...arguments);
  }

  //按月度导出订单明细
  report_exportDetailByMonth() {
    return super.get('report/exportDetailByMonth', ...arguments);
  }

  //按月度查询订单明细
  report_getInfoByMonth() {
    return super.post('report/getInfoByMonth', ...arguments, {});
  }

  // 修改用户到店离店时间
  report_updateAtimeAndLtime() {
    return super.post('report/updateAtimeAndLtime', ...arguments);
  }
  // 查看往期评分
  report_getHistoryByStoreId() {
    return super.post('report/getHistoryByStoreId', ...arguments);
  }
  // 编辑报告基本信息
  report_updateReportBaseInfo() {
    return super.post('report/updateReportBaseInfo', ...arguments);
  }

  /**
   * 超时待审核报告列表
   * @param {Array} reportOvertimeDto -  reportOvertimeDto
   */
  report_getOvertimeToAuditList() {
    return super.post('report/getOvertimeToAuditList', ...arguments);
  }

  // 修改打卡任务门店地址
  report_updatePunchReportStoreInfo() {
    return super.post('report/updatePunchReportStoreInfo', ...arguments);
  }

  /**
   * 用户任务平均分
   * @param {string} uid -  用户id
   */

  report_userTaskAvgScore() {
    return super.post('report/userTaskAvgScore', ...arguments);
  }
  //报告交付 批量分析
  report_batchAnalysis() {
    return super.post('report/batchAnalysis', ...arguments);
  }
  //查看用户的任务记录
  report_userTaskRecord2P() {
    return super.post('report/userTaskRecord2P', ...arguments);
  }
  //下载凭证
  report_exportReportDocument() {
    return super.get('report/exportReportDocument', ...arguments);
  }
  /**
   * 兼职报告审核列表
   * @param {Array} partTimeReportBO -  partTimeReportBO
   */

  report_partTimeReportAuditList() {
    return super.post('report/partTimeReportAuditList', ...arguments, {});
  }
  /**
   * 生效报告
   * @param {string} reportId -  reportId
   */
  report_rollbackMarkDelete() {
    return super.post('report/rollbackMarkDelete', ...arguments);
  }

  /**
   * 报告标签
   * @returns
   */
  report_reportLabel() {
    return super.post('report/reportLabel', ...arguments);
  }
  /**
   * 获取报告分享id
   * @param {string} reportLogId -  reportLogId
   */
  report_getReportShareId() {
    return super.post('report/getReportShareId', ...arguments);
  }
  /*
   * 报告录音文件
   * @returns
   */
  report_getReportAllSoundFile() {
    return super.post('report/getReportAllSoundFile', ...arguments);
  }
  /**
   * 文件转换
   * @returns
   */
  file_fileTransferFormat() {
    return super.post('file/fileTransferFormat', ...arguments);
  }
}
